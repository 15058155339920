import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SInput, SPage, STable2, SLoad, SText, SView, SHr, SPopup, SNavigation, SStorage } from 'servisofts-component'
import Btn from '../../Components/Btn'
import Container from '../../Components/Container'

import SSocket from 'servisofts-socket'
import Model from '../../Model'

export default class send extends Component {
    state = {
        descripcion: "Notificacion por tags.",
        observacion: "Prueba de notificaciones por tags.",
        url: "",
        data: {
            deepLink: "",
        }
    }

    componentDidMount() {
        SStorage.getItem("notification_data", (e => {
            this.state.ready = true;
            if (e) {
                let data = JSON.parse(e);
                this.state = data;
                // this.state.descripcion = data.descripcion;
                // this.state.observacion = data.observacion;
                // this.state.url_image = data?.url_image;
                // this.state.data.deepLink = data?.deeplink;
            }
            this.setState({ ...this.state })
        }))
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
        return true;
    }

    save() {
        let data = {
            descripcion: this.inp_descripcion.getValue() ?? this.state.descripcion,
            observacion: this.inp_observacion.getValue() ?? this.state.observacion,
            // url_image: this.inp_url.getValue() ?? this.state.url_image,
            data: {
                //     deepLink: this.inp_deeplink.getValue() ?? this.state.data.deepLink
            }
        }
        SStorage.setItem("notification_data", JSON.stringify(data));
    }

    handleSend(tags) {
        if (this.state.loading) return;
        this.state.loading = true;
        this.setState({ loading: true })
        let data = {
            descripcion: this.inp_descripcion.getValue() ?? this.state.descripcion,
            observacion: this.inp_observacion.getValue() ?? this.state.observacion,
            url_image: this.inp_url.getValue() ?? this.state.url_image,
            data: {
                //     deepLink: this.inp_deeplink.getValue() ?? this.state.data.deepLink,
                //     // key_empresa: Model.empresa.Action.getKey(),
            }

        }
        SStorage.setItem("notification_data", JSON.stringify(data));
        SSocket.sendPromise({
            service: "notification",
            component: "notification",
            type: "sendV2",
            key_usuario_emisor: Model.usuario.Action.getKey(),
            // type: "send",
            data: data,
            tags: tags
        }).then(e => {
            SPopup.alert(`Se notifico a ${e.data} dispositivos.`)
            this.setState({ loading: false })
            console.log(e);
        }).catch(e => {
            this.setState({ loading: false })
            console.error(e);
        })
    }

    componetSendApp({ app, key }) {
        {
            return <SView margin={10}>
                <SInput
                    type={"checkBox"}
                    onChangeText={(e) => {
                        // handler key
                    }}
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                    }}
                />
                <SText >{app}</SText>
            </SView>
        }
    }


    render() {
        if (!this.state.ready) return <SLoad />
        return <SPage>
            <Container>
                <SInput ref={ref => this.inp_descripcion = ref} label={"Titulo"} defaultValue={this.state.descripcion} />
                <SInput ref={ref => this.inp_observacion = ref} label={"Body"} type={"textArea"} defaultValue={this.state.observacion} />
                <SInput ref={ref => this.inp_url = ref} label={"Image url"} defaultValue={this.state.url_image} />
                {/* {<SInput ref={ref => this.inp_deeplink = ref} label={"deeplink"} defaultValue={this.state.data.deepLink} />} */}
                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    this.save();
                }}>GUARDAR</Btn>

                <SHr />

                {/* No soporta multiple apps */}
                {/* <SView row center>
                    <SText bold fontSize={20}>Enviar a App:</SText>
                    <SHr h={10} />
                    {this.componetSendApp({ app: "Tapeke", key: "client" })}
                    {this.componetSendApp({ app: "Partner", key: "partner" })}
                    {this.componetSendApp({ app: "Driver", key: "driver" })}
                    {this.componetSendApp({ app: "Admin", key: "admin" })}
                    <SHr h={10} />
                </SView> */}

                <SView card margin={2} padding={10}>
                    <SText fontSize={15} >Enviar a App (todos los usuario):</SText>
                    <SHr />
                    <SInput ref={ref => this.appSelect = ref} type={"select"} options={[
                        { key: "", content: "Seleccionar" },
                        { key: "client", content: "Tapeke" },
                        { key: "partner", content: "Partner" },
                        { key: "driver", content: "Driver" },
                        { key: "admin", content: "Admin" },
                    ]}
                    />
                    <SHr />
                    <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                        SPopup.confirm({
                            title: "¿Estás seguro de enviar esta notificación?",
                            onPress: () => {
                                this.handleSend({
                                    app: this.appSelect.getValue()
                                })
                            },
                        });
                    }}>Enviar</Btn>
                </SView>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                key_usuario: e.key,
                            })
                        },
                    });
                }}>Todos los tokens.</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                platform: "web",
                            })
                        },
                    });
                }}>Todos los navegadores web.</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                platform: "ios",
                            })
                        },
                    });
                }}>Todos los IOS o Apple (Todas las APPS)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                platform: "ios",
                                app: 'client'
                            })
                        },
                    });
                }}>Todos los IOS o Apple (App cliente)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                platform: "android",
                            })
                        },
                    });
                }}>Todos los Android (Todas las APPS)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                platform: "android",
                                app: "client"
                            })
                        },
                    });
                }}>Todos los Android (App cliente)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {

                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                key_usuario: "is null",
                            })
                        },
                    });
                }}>Dispositivos sin session (Todas las APPS)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {

                    SPopup.confirm({
                        title: "¿Estás seguro de enviar esta notificación?",
                        onPress: () => {
                            this.handleSend({
                                key_usuario: "is null",
                                app: "client"
                            })
                        },
                    });
                }}>Dispositivos sin session (App cliente)</Btn>


                {/* <SHr /> */}
                {/* <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    this.handleSend({
                        user_type: "cliente",
                    })
                }}>Todos los clientes.</Btn> */}

                {/* <SHr /> */}
                {/* <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    this.handleSend({
                        user_type: "admin",
                    })
                }}>Todos los administradores.</Btn> */}

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SNavigation.navigate("/usuario/list", {
                        onSelect: (e) => {
                            SPopup.confirm({
                                title: "¿Estás seguro de enviar esta notificación?",
                                onPress: () => {
                                    this.handleSend({
                                        key_usuario: e.key,
                                    })
                                },
                            });
                        }
                    })
                }}>Notificar a un usuario (Todas las APPS)</Btn>

                <SHr />
                <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SNavigation.navigate("/usuario/list", {
                        onSelect: (e) => {
                            SPopup.confirm({
                                title: "¿Estás seguro de enviar esta notificación?",
                                onPress: () => {
                                    this.handleSend({
                                        key_usuario: e.key,
                                        app: "client"
                                    })
                                },
                            });
                        }
                    })
                }}>Notificar a un usuario (App Cliente)</Btn>


                <SHr h={100} />
                {/* <SText>Dev Tools</SText> */}
                {/* <Btn col={"xs-12"} loading={this.state.loading} onPress={() => {
                    SSocket.sendPromise({
                        component: "general",
                        type: "test",
                        data: { obj: "test" },
                        key_usuario: Model.usuario.Action.getKey(),
                    }).then(e => {
                        SPopup.alert(`Se envio la promesa a server.`)
                    }).catch(e => {
                        this.setState({ loading: false })
                        console.error(e);
                    })
                }}>Enviar Promesa a server</Btn> */}
                {/* <SHr h={100} /> */}
            </Container>
        </SPage >
    }
}